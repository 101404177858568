import React from 'react';
import Img, { GatsbyImageProps } from 'gatsby-image';
import { ImageSharp } from '../../graphql-types';

export type ImageInfo = {
  image?: string | { childImageSharp: ImageSharp };
  childImageSharp?: ImageSharp;
  alt?: string;
};

interface PreviewCompatibleImageProps extends GatsbyImageProps {
  imageInfo: ImageInfo;
  className?: string;
}

const PreviewCompatibleImage: React.FC<PreviewCompatibleImageProps> = ({
  imageInfo,
  className,
  ...props
}) => {
  const { alt = '', childImageSharp, image } = imageInfo;

  if (!!image && typeof image === 'object') {
    return <Img {...props} className={className} fluid={image.childImageSharp.fluid} alt={alt} />;
  }

  if (childImageSharp) {
    return <Img {...props} className={className} fluid={childImageSharp.fluid} alt={alt} />;
  }

  if (!!image && typeof image === 'string')
    return (
      <img
        {...(props as React.HTMLAttributes<HTMLImageElement>)}
        className={className}
        src={image}
        alt={alt}
      />
    );

  return null;
};

export default PreviewCompatibleImage;
